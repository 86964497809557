@use '../../Partials/font.scss' as*;
@use '../../Partials/mixins.scss' as*;


.header{
    position: absolute;
    width: 100%;
    // background-color: rgba(255,255,255,0);
    color: whitesmoke;
    text-shadow: 2px 2px #fe2079;
    z-index: 3;
    text-decoration: none;
    font-size: 20px;
    user-select: none;

    @include tablet{
        font-size: 2rem;
    }

    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5rem;

        @include tablet{
            flex-direction: row;
            justify-content: space-around;
        }
        
        @include desktop{
            padding-right: 2rem;
        }

        &__left{
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            font-size: 20px;
            font-weight: 600s;
            text-shadow: 1px 1px #fe2079;

            @include mobileLarge{
                font-size: 28px;
            }

            @include tablet{
                margin-left: 3rem;
            }
        }

        &__right{
            flex: 1;
            display: flex;
            justify-content: center;
            font-size: 20px;

            @include mobileLarge{
                font-size: 28px;
            }

            @include tablet{
                justify-content: flex-end;
                margin-right: 3rem;
            }

            @include desktop{
                justify-content: flex-end;
            }
            
        }

    }
    &-logo{
        margin: 0;
    }

    &-menu{
        display: flex;
        flex-direction: row;
    }

    &-home{
        margin: 0;
    }

    &-about {
        margin: 0;
    }

    &-project{
        margin: 0;

    }

    &-bar{
        margin: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.link{
    text-decoration: none;
    color: white;


    &:hover{
        text-decoration: none;
        animation: pulse 0.7s ease-in-out infinite, wiggle 0.4s ease-in infinite;
    }
}

@keyframes pulse {
    0% {
      color: #eee;
      opacity: 1;
    }
    50% {
      color: #fe2079;
    //   opacity: 0.7;
    }
    100% {
      color: #eee;
      opacity: 1;
    }
  }
  
  @keyframes wiggle {
    0% {
      transform: translateY(0%);
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(0%);
    }
  }
  