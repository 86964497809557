@use '../../Partials//font.scss' as*;
@use '../../Partials/mixins.scss' as*;


@keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }

  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

.about{
    left: 100%;
    right: 100%;
    user-select: none;
    overflow-x: hidden;
    overflow-y: hidden;

    &-container{
        display: flex;
        justify-content: center;
    }

    &-title{
        text-shadow: 2px 2px gray;
        color: #fe2079;
        padding-top: 5rem;
        padding-bottom: 1rem;
        font-size: 2rem;
        font-weight: 900;
        animation: tracking-in-expand 0.6s ease-out;

        @include tablet {
            text-shadow: 2px 2px gray;
            padding-top: 3rem;
            font-size: 3rem;
        }

        @include desktop{ 
            text-shadow: 2px 2px gray;
            font-size: 5rem;
        }

        @include desktopLarge{
        }
    }

    &-card{
        background-color: rgba(0,0,0,0.5);
        border-radius: 1rem;
        padding-bottom: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        animation: puff-in-center 0.6s ease-in-out ;

        @include desktop{
            margin-left: 2rem;
            margin-right: 2rem;
        }
        
        &__container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;

            @include tablet{
                padding-left: 2rem;
                padding-right: 2rem;
            }

        }

        &__description{
            font-weight: 300;
            color: white;
            text-shadow: 1px 1px gray;
            font-size: 20px;

            @include tablet{
                font-size: 1.5rem;
            }

            @include desktop{
                font-size: 2rem;
            }
        }

        &__contact{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 20px;
            font-weight: 600;

            @include tablet{
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                flex-direction: row;
                justify-content: space-around;
                font-size: 1.5rem;
            }

            @include desktop{
                font-size: 2rem;
            }
            
            &-linkedin{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            color: white;
            text-decoration: none;
            color: white;
            padding-top: 1rem;
            padding-bottom: 1.5rem;

            @include tablet{
                padding-top: 0rem;
                padding-bottom: 0rem;
            }
        
            &:visited{
                text-decoration: none;
            }
        
            &:hover{
                text-decoration: none;
                color: #fe2079;
                animation: wiggle 0.4s ease-in infinite;
            }
        
           &:focus{
               text-decoration: none;
           }
               &-logo{
                width: 31.5px;
                height: 31.5px;

                    @include desktop{
                        width: 51.5px;
                        height: 51.5px;
                    }
               }
               
               &-text{
                margin: 0;
                margin-left: 0.5rem;

                @include tablet{
                    margin-left: 1rem;
                }

                @include desktop{
                    margin-left: 1.5rem;
                }
               }
            }

            &-github{
                display: flex;
                flex-direction: row;
                color: white;
                text-decoration: none;
                color: white;
                padding-bottom: 1.5rem;
                @include tablet{
                    padding-bottom: 0rem;
                }

                &:visited{
                    text-decoration: none;
                }
            
                &:hover{
                    text-decoration: none;
                    color: #fe2079;
                    animation: wiggle 0.4s ease-in infinite;
                }
            
               &:focus{
                   text-decoration: none;
               }

                &-logo{
                    width: 31.5px;
                    height: 31.5px;

                    @include desktop{
                        width: 51.5px;
                        height: 51.5px;
                    }
                }
                
                &-text{
                    margin: 0;
                    margin-left: 1rem;
                
                @include tablet{
                    margin-left: 1rem;
                }
 
                 @include desktop{
                     margin-left: 1.5rem;
                 }
                }
            }

            &-contact{
                display: flex;
                flex-direction: row;
                color: white;
                text-decoration: none;
                color: white;
                
                
                &:visited{
                    text-decoration: none;
                }
            
                &:hover{
                    text-decoration: none;
                    color: #fe2079;
                    animation: wiggle 0.4s ease-in infinite;
                }
            
               &:focus{
                   text-decoration: none;
               }

                &-logo{
                    width: 31.5px;
                    height: 31.5px;


                    @include desktop{
                        width: 51.5px;
                        height: 51.5px;
                    }
                }
                
                &-text{
                    margin: 0;
                    margin-left: 0.5rem;

                @include tablet{
                    margin-left: 1rem;
                }

                 @include desktop{
                     margin-left: 1.5rem;
                 }
                }
            }
            
        }
        
    }
    
}

@keyframes wiggle {
    0% {
      transform: translateY(0%);
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(0%);
    }
  }