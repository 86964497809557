@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../Assets/fonts/TitilliumWeb-Black.ttf');
    font-weight: 900;
    font-style: normal;
}