@use './Partials/font.scss' as *;

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root{
font-family: 'TitilliumWeb';

}


