@use '../../Partials/font.scss' as*;
@use '../../Partials/mixins.scss' as*;

.scroll{
    position: absolute;
    background-color: transparent;
    left: 0;    
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow-y: scroll;
}
@keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }

  @keyframes text-pop-up-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
  }

.projects-card-container{
    &:hover{
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.projects{
    font-family: 'TitilliumWeb';
    left: 100%;
    right: 100%;
    user-select: none;
    overflow-x: hidden;
    overflow-y: hidden;
    
    
    &-container{
        display: flex;
        justify-content: center;

    }

    &-title{
        text-shadow: 2px 2px gray;
        color: #fe2079;
        padding-top: 5rem;
        font-size: 2rem;
        font-weight: 900;
        animation: tracking-in-expand 0.6s ease-in-out;


        @include tablet {
            text-shadow: 2px 2px gray;
            padding-top: 3rem;
            font-size: 3rem;
        }

        @include desktop{ 
            text-shadow: 2px 2px gray;
            font-size: 5rem;
        }

    
    }

    &-card{
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 2rem;

        @include tablet{
            margin-bottom: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @include desktop{
            margin-bottom: 3rem;
        }

        @include desktopLarge{
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            margin-bottom: 3rem;
        }

        &-container{
            display: flex;
            flex-direction: column;
            color: white;
            text-shadow: 2px 2px black;
            background-color: rgba(0,0,0,0.4);
            border-radius: 1rem;
            align-items: center;
            animation: puff-in-center 0.6s ease-out ;
            

            @include tablet{
                flex-direction: row;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            @include desktop{
                margin-left: 4rem;
                margin-right:4rem;
                padding-left: 1rem;
            }

            @include desktopLarge{
                min-width: 90%;
                max-width: 100%;
            }
        }

        &__image{
            margin-top: 1rem;
            border-radius: 16px;
            width: 90%;
            height: 90%;

            @include tablet{
                margin-top: auto;
                margin-bottom: auto;
                width: 40%;
                height: 40%;
            }

            @include desktop{
                margin-top: auto;
                margin-bottom: auto;
                width: 50%;
                height: 50%;
                
            }

            @include desktopLarge{
                width: 520px;
                height: 294px;   
            }
        }

        &__textbox{
            padding-top: 2rem;
            padding-bottom: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;

            @include tablet{
                padding-left: 1.5rem;
            }

            @include desktop{
                padding-left: 3rem;
                max-width: 100%;
            }

            @include desktopLarge{

            }

            &-title{
                font-weight: 600;
                font-size: 1.5rem;
                text-shadow: 1px 1px rgba(254, 32, 121, 0.5);

                @include tablet{
                    font-size: 2rem;
                    padding-top: 1rem;
                }

                @include desktop{
                    font-size: 2.5rem;
                }

          
            }

            &-description{
                margin: 0;
                text-shadow: 1px 1px rgba(0,0,0, 1);

                @include tablet{
                    font-size: 1rem;
                    height: 120px;
                }

                @include desktop{
                    margin-right: 1rem;
                    height: 180px;
                    font-size: 1.5rem;
                }

            }


            &-link-container{
                display: flex;
                margin-top: 1rem;
                justify-content: center;

                @include tablet{
                    margin-bottom: 1rem;
                }

                @include desktop{
                    padding-top: 3rem;
                }

                @include desktopLarge{
                    justify-content: center;
                    margin-left: auto;
                    margin-right: auto;
                }
             
            }

            &-link{
                border: none;
                border-radius: 16px;
                text-align: center;     
                font-weight: bold;
                cursor: pointer;
                text-decoration: none;
                color: #fe2079;
                background-color: white;
                text-shadow: none;
                padding: 12px 24px;
                
                &:hover{
                    animation: pulsate-bck 0.6s ease-in infinite;
                }

                @include tablet{
                    width: 100px;
                    max-width: 100px;
                    padding: 12px 24px;
                }

                @include desktop{
                    width: 120px;
                    max-width: 120px;
                    padding: 16px 32px;
                }

           
            }

            &-git{
                color: #f5f5f5;
                background-color: #fe2079;
                margin-right: 2rem;
            }
            
        }
        
    }


}

@keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

@keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
              transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }