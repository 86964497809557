@use '../Partials/font.scss' as*;
@use '../Partials/mixins.scss' as*;

.landing{
    position: absolute;
    width: min-content;
    height: min-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    &-title{
        color: whitesmoke;
        text-shadow: 2px 2px #fe2079;
        margin: 0;
        font-size: 30px;

        @include tablet{
            font-size: 3rem;
            text-shadow: 4px 4px #fe2079;
        }

        @include desktop{
            font-size: 80px;
        }

    }

    &-subtitle{
        color: whitesmoke;
        text-shadow: 2px 2px #fe2079;
        font-size: 20px;
        margin: 0;
        padding-top: 1rem;

        @include tablet{
            font-size: 24px;
            padding-top: 2rem;
            text-shadow: 3px 3px #fe2079;
        }

        @include desktop{
            font-size: 48px;
            text-shadow: 4px 4px #fe2079;
        }

    }
}