@use '../../Partials/font.scss' as*;
@use '../../Partials/mixins.scss' as*;

.loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #141622CC;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 300ms ease;
    transition: background-color 1000ms ease;
    z-index: 4;
}


.overlay{
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    &-title{
        
        color: #fe2079;
        margin: 0;
        font-size: 30px;
        font-weight: bold;

        @include tablet{
            font-size: 3rem;
        }

        @include desktop{
            font-size: 80px;
        }

    }

    &-subtitle{
        color: #fe2079;
        font-size: 20px;
        margin: 0;
        padding-top: 3rem;
        font-weight: 100;

        @include tablet{
            font-size: 24px;
            padding-top: 2rem;
        }

        @include desktop{
            font-size: 48px;
        }

        @include desktopLarge{
    
            
        }
    }
}